<template>
    <div class="reservesPage">
        <HeaderLogo :loginVariant="false" :showDexTotal="true" :pageTitle="'Time share'"/>
        <Suspense>
        <template #default>
          <TimeShareSwiperComponent />
        </template>
        <template #fallback>
          <div class="text-center" style="margin-top: 15px;">Loading ...</div>
        </template>
        </Suspense>
    </div>
</template>
<script>
import HeaderLogo from "../../components/HeaderLogo.vue";
import TimeShareSwiperComponent from "./components/TimeShareSwiperComponent.vue";
import { Suspense } from "vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "@/assets/css/global.css"

export default {
  name: "TimeSharePage",
  components: {
        HeaderLogo,
        TimeShareSwiperComponent,
        Suspense,
  },
  created() {
    if (localStorage.getItem("user_type") != "Client") {
      this.$router.push("/");
    }
  },
};
</script>

<style>
h1 {
    font: normal normal bold 24px Helvetica Neue;
    color: black;
    text-align: center;
}

</style>
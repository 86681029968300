<template>
    <div class="trainingListPage">
      <div class="header">
        <HeaderLogo :loginVariant="false" :showDexTotal="true" :pageTitle="'Treinamentos'"/>
      </div>
    <div class="body">
      <div class="content">
      <ul class="training-list">
        <li class="training-card" v-for="(training, index) in trainingData" :key="index">
          <img class="training-icons" :src="getBackend() + '/media/' + training.icon" :alt="training.name" />
          <p class="training-title">{{ training.name }}</p>
        </li>
      </ul>
      </div>
    </div>
      <div class="button-back text-center">
        <button class="btn btn-circle button-black rounded-button" @click="$router.go(-1)">
          <BIconArrowLeft />
        </button>
      </div>
    </div>
</template>

<script>
  import { BIconArrowLeft } from "bootstrap-icons-vue";
  import HeaderLogo from "../../components/HeaderLogo.vue";
  import { apiService } from "@/util/api.service";

  import "@/assets/css/global.css"

  export default {
    name: "TrainingListPage",
    components: {
      HeaderLogo,
      BIconArrowLeft
    },
    props: {
    },
    data() {
      return {
          trainingData: [],
      }
    },
      mounted() {
    apiService.getTrainings((response) => {
      this.trainingData = response.data;
    });
  },
  methods: {
    getBackend: function () {
      return apiService.baseURL;
    },
  },
  created() {
      if (localStorage.getItem("user_type") != "Client") {
        this.$router.push("/");
      }
    },
  };
</script>

<style scoped>
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

#app {
  padding-bottom: 30px;
}

h1 {
  font: normal normal bold 24px Helvetica Neue;
  color: black;
  text-align: center;
}

.quota-title-spacer {
  margin: auto;
}

.training-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.training-title {
  text-align: center;
}

.training-icons {
  width: 150px;
}

.training-list {
  display: flex;
  padding: 22px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 15px;
}

.button-back {
  padding-bottom: 30px;
}

@media screen and (min-width: 568px) {
  .training-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }
}

@media screen and (min-width: 1068px) {

  .content {
    display: flex;
    justify-content: center;
  }

  .training-list {
    width: 50%;
  }
}


</style>
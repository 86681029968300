<template>
  <div>
    <div class="header">
      <HeaderLogo :loginVariant="false" :showDexTotal="true" :pageTitle="'Meus dados'" />
      <div class="quota-title">Dados do Cliente</div>
      <div class="quota-title-spacer"></div>
    </div>
    <div class="body">
      <div class="profile-image">
        <img :src="profilePhoto" alt="Avatar" />
        <p class="account-username">{{ this.full_name }}</p>
        <router-link to="/changepassword">
          <button class="changepass-button" @click="changePassword">
            Alterar senha
          </button>
        </router-link>
      </div>
      <ul class="training-list">
        <li v-for="(training, index) in trainings" :key="index">
          <img :src="getBackend() + '/media/' + training.icon" :alt="training.name" />
        </li>
      </ul>
      <form class="form">
        <h1 class="subtitle mt-3">Informações da conta</h1>
        <div class="form-group px-3 mt-2">
          <label for="full_name" class="col-sm-2">Nome</label>
          <input type="text" class="form-control" id="full_name" v-model="this.full_name" disabled="true">
        </div>
        <div class="form-group px-3">
          <label for="email" class="col-sm-2">Email</label>
          <input type="text" class="form-control" id="email" v-model="this.email" disabled="true">
        </div>
        <div class="form-group px-3">
          <label for="phone " class="col-sm-2">Telefone</label>
          <input type="text" class="form-control" id="phone" v-model="this.phone" disabled="true">
        </div>
        <h1 class="subtitle mt-3">Endereço</h1>
        <div class="form-group px-3 mt-2">
          <label for="address">Endereço com número</label>
          <input type="text" class="form-control" id="address" v-model="this.address" disabled="true">
        </div>
        <div class="row px-3">
          <div class="col split-right">
            <label for="city">Cidade</label>
            <input type="text" class="form-control" id="city" v-model="this.city" disabled="true">
          </div>
          <div class="col split-left">
            <label for="cep">Cep</label>
            <input type="text" class="form-control" id="cep" v-model="this.cep" disabled="true">
          </div>
        </div>
        <div class="form-group px-3">
          <label for="state">Estado</label>
          <input type="text" class="form-control" id="state" v-model="this.state" disabled="true">
        </div>
      </form>
    </div>
    <div class="button-back text-center mt-3">
      <button class="btn btn-circle button-black rounded-button" @click="$router.go(-1)">
        <BIconArrowLeft />
      </button>
    </div>
  </div>
</template>

<script>
import { BIconArrowLeft } from "bootstrap-icons-vue";
import HeaderLogo from "../../components/HeaderLogo.vue";
import { apiService } from "../../util/api.service";

export default {
  name: "AccountPage",
  components: {
    HeaderLogo,
    BIconArrowLeft,
  },
  created() {
    if (localStorage.getItem("user_type") != "Client") {
      this.$router.push("/");
    }
  },
  computed: {
    username: function () {
      return localStorage.getItem('username') != undefined ? localStorage.getItem('username') : "Fulano";
    },
    profilePhoto: function () {
      if (localStorage.getItem('profilePhoto') != "null" && localStorage.getItem('profilePhoto') != undefined) {
        return apiService.baseURL + localStorage.getItem('profilePhoto');
      }
      return require("../../assets/img/img_avatar.png");
    },
  },
  data() {
    return {
      trainings: undefined,
      full_name: undefined,
      email: undefined,
      phone: undefined,
      address: undefined,
      neighborhood: undefined,
      city: undefined,
      cep: undefined,
      state: undefined,
    }
  },
  mounted() {
    apiService.getClientAccount((response) => {
      this.trainings = response.data['trainings'];
      this.full_name = response.data['full_name'];
      this.email = response.data['email'];
      this.phone = response.data['phone'];
      this.address = (response.data['address'] || "") + " " + (response.data['address_number'] || "") + " " + (response.data['complement'] || "");
      this.neighborhood = response.data['neighborhood'];
      this.city = response.data['city'];
      this.cep = response.data['cep'];
      this.state = response.data['state'];
    });
  },
  methods: {
    getBackend: function () {
      return apiService.baseURL;
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*,
/*resetar o estilo da página*/
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

.body {
  margin: 8px;
  padding: 8px;
}

.header {
  margin-bottom: 30px;
}

.black-outline {
  border: 1px solid #000;
}

.profile-image {
  /* margin: 10px; */
  padding: 15px;
  height: 120px;
  margin-top: 24px;
  border-radius: 5%;
  background-color: #d1d1d147;
  font-size: 20px;
}

.profile-image img {
  width: 90px;
  height: 90px;
  border-radius: 10%;
  float: left;
  margin-right: 10px;
}

.account-username {
  margin-top: auto;
  font-size: 18px;
  font-weight: bolder;
  text-align: left;
}

.changepass-button {
  font-weight: bold;
  font-size: 11px;
  flex: 1 0 21%;
  margin-top: 32px;
  text-align: center;
  width: 120px;
  height: 26px;
  border: 1px solid #000000;
  border-radius: 40px;
  float: right;
}

.subtitle {
  text-align: left;
  margin: 0 25px 0 0px;
}

.form-control {
  padding: 8px !important;
  margin-bottom: 8px;
}

.split-right {
  margin-right: 2px;
}

.split-left {
  margin-left: 2px;
}

.training-list {
  list-style: none;
  margin: 8px 16px 0 16px;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.training-list li {
  display: flex;
  align-items: center;
}

.training-list img {
  width: 50px;
  height: 50px;
}

@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
</style>

<template>
  <div class="reservedetails vh-100 vw-100">
    <HeaderLogo :loginVariant="false" :showDexTotal="true" :pageTitle="'Timeshare'" />
    <div class="asset">
      <TimeShareAssetComponent @updateDate="updateDate" v-if="isLoaded" :data="asset" />
      <loading v-model:active="loading" :can-cancel="false" :is-full-page="true"></loading>
      <div @click="showResume" :disabled="loading" class="reservar">
        Salvar
      </div>
    </div>


    <GDialog :contentClass="'bottomModal'" v-model="dialogState" max-width="500"
      transition="custom-from-bottom-transition">
      <div v-bind:class="[successfull ? 'wrapper wrapper-success' : 'wrapper wrapper-failure']">
        <div class="content-modal">
          <div class="title">{{ title }}</div>
          <div class="message">{{ message }}</div>
        </div>
        <div class="actions-modal">
          <button class="btn btn-circle button-black modal-button rounded-button" @click="modalAction">
            OK
          </button>
        </div>
      </div>
    </GDialog>

    <GDialog :contentClass="'bottomModal'" v-model="dialogResumeState" max-width="500"
      transition="custom-from-bottom-transition">
      <div class="wrapper-resume">
        <div class="content-modal">
          <div class="title">Resumo da reserva</div>
          <div class="message">Período da reserva: <b>{{ resumeDate() }}</b></div>
          <div class="message">Custo total da reserva: <b>{{ dexUsageStr() }}</b></div>
        </div>
        <div class="actions-modal">
          <button class="btn btn-circle button-black modal-button rounded-button confirm-button" @click="makeReserve">
            Confirmar
          </button>
          <button class="btn btn-circle button-black modal-button rounded-button cancel-button"
            @click="modalResumeAction">
            Cancelar
          </button>
        </div>
      </div>
    </GDialog>

  </div>
</template>

<script>
import HeaderLogo from "../../components/HeaderLogo.vue";
import TimeShareAssetComponent from "./components/TimeShareAssetComponent.vue";
import { apiService } from "../../util/api.service";
import VueLoading from 'vue-loading-overlay';

export default {
  name: "TimeshareCreateReserve",
  components: {
    HeaderLogo,
    TimeShareAssetComponent,
    Loading: VueLoading,
  },
  props: {
    asset_id: String
  },
  data() {
    return {
      asset: undefined,
      isLoaded: false,
      date_list: [],
      dialogState: false,
      dialogResumeState: false,
      successfull: false,
      loading: false,
      dexTotal: 0,
    }
  },
  async mounted() {
    this.loading = false;
    this.isLoaded = false;
    await apiService.getTimeshareAsset(this.asset_id, (response) => {
      this.asset = response.data;
    });
    await apiService.getDexTotal((response) => {
      this.dexTotal = response.data.dex_total;
    });
    this.isLoaded = true;
  },
  methods: {
    back() {
      this.$router.push("/timeshare");
    },
    updateDate(newDate) {
      if (!this.date_list.includes(newDate)) {
        this.date_list.push(newDate)
      } else {
        this.date_list = this.date_list.filter(x => x != newDate)
      }
    },
    resumeDate() {
      if (this.date_list.length > 0) {
        var dateObjects = this.date_list.map(dateString => new Date(dateString + "T00:00:00Z"));
        dateObjects.sort((a, b) => a - b);
        var formattedDates = dateObjects.map(date => this.formatDate(date));

        var sequential = true;
        for (let i = 0; i < dateObjects.length - 1; i++) {
          if ((dateObjects[i + 1] - dateObjects[i]) / (1000 * 60 * 60 * 24) !== 1) {
            sequential = false;
            break;
          }
        }

        if (sequential) {
          var firstDateFormatted = formattedDates[0];
          var lastDateFormatted = formattedDates[formattedDates.length - 1];
          return `${firstDateFormatted} até ${lastDateFormatted}`;
        } else {
          return formattedDates.join(", ");
        }
      }
      return `Sem data selecionada!`;
    },
    dexUsage() {
      if (this.asset !== undefined) {
        return (this.date_list.length * this.asset.dex_per_day);
      }
      return 0;
    },
    dexUsageStr() {
      return this.dexUsage() + " dex"
    },
    formatDate(date) {
      var day = date.getUTCDate().toString().padStart(2, '0');
      var month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      return `${day}/${month}`;
    },
    showResume() {
      console.log(this.dexTotal)
      if (this.dexTotal < this.dexUsage()) {
        this.title = "DEX insuficiente"
        this.message = "Você não tem DEX suficiente para reservar os dias selecionados. O seu saldo é de " + this.dexTotal;
        this.dialogState = true;
      } else {
        this.dialogResumeState = true;
      }
    },
    async makeReserve() {
      this.dialogResumeState = false;
      if (this.date_list != undefined) {
        this.loading = true;
        await apiService.postReserveAssetTimeshare(
          this.date_list,
          this.asset.id,
          (response) => {
            this.message = response.data.message
            this.title = response.data.title
            if (response.data.reserve != undefined && response.data.reserve.length > 0) {
              this.successfull = true;
            } else {
              this.successfull = false;
            }
            this.loading = false;
          },
          (error) => {
            this.title = error.response.data.title;
            this.message = error.response.data.message;
            this.successfull = false;
            this.loading = false;
          });
        this.dialogState = true;
      }
    },
    modalAction() {
      this.dialogState = false;
      if (this.successfull) {
        this.$router.push("/timeshare");
      }
    },
    modalResumeAction() {
      this.dialogResumeState = false;
      if (this.successfull) {
        this.$router.push("/timeshare");
      }
    }
  },
};
</script>

<style>
.bottomModal {
  margin: auto auto 0 auto !important;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25) !important;
  border-radius: 15px 15px 0px 0px !important;
  transform: translateY(0px);
}

.custom-from-bottom-transition,
.custom-from-bottom-transition-enter-from {
  transform: translate(0, 100%);
  opacity: 0;
}

.custom-from-bottom-transition-leave-to {
  transform: translate(0, 50%);
  opacity: 0;
}

.g-dialog-content {
  max-width: none !important;
}

.g-dialog-overlay {
  background: #dbdbdb82 !important;
}

.vc-container {
  background-color: #EFF1F3 !important;
  border-color: #EFF1F3 !important;
}

.vc-title {
  text-transform: capitalize;
}

.vc-weekday {
  padding-bottom: 16px !important;
  border-bottom: 1px solid #D1D3D4;
  text-transform: capitalize;
}
</style>

<style scoped>
.reservedetails {
  text-align: center;
}

.asset {
  margin-top: 18px;
}

.reservar {
  max-width: 180px;
}

.wrapper {
  color: #000;
  border-radius: 15px 15px 0px 0px !important;
}

.wrapper-failure {
  border-top: 5px solid #FF0000;
}

.wrapper-success {
  border-top: 5px solid #3AC948;
}

.wrapper-resume {
  border-top: 5px solid #4468AD;
}

.content-modal {
  padding: 16px;
  margin: 16px 0 16px 0;
}

.content-modal .title {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 2px;
  margin-bottom: 42px;
}

.message {
  font-family: 'Open Sans', sans-serif;
  color: black;
  letter-spacing: 0px;
  margin: auto;
  font-size: 18px;
  text-align: center;
}

.actions-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px 16px;
  border-top: 0px;
  margin: 16px auto 16px;
}

.modal-button {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  background: black;
  color: white;
  width: 174px;
  height: 42px;
}

.confirm-button {
  background: green;
}

.cancel-button {
  background: red;
}

@import "~bootstrap/dist/css/bootstrap.css";
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
</style>
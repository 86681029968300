<template>
  <div v-if="data && data.asset" class="assetcomponent">
    <div class="card card-image">
      <div class="card-body text-center">
        <div class="asset-description">
          <img class="img" :src="getImage()" />
          <div class="asset-info">
            <div class="asset-name">{{ data.asset.name }}</div><br />
            <div class="asset-time"><b>Horário:</b> {{ data.working_hours }}</div><br />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showCalendar" class="card card-calendar">
      <div class="card-body text-center">
        <CalendarComponent @changeDay="sendNewDate"
        @updateFreeDaysSelected="updateFreeDaysSelected"
        @updateWeekDaysSelected="updateWeekDaysSelected" 
        :asset_id="data.asset.id" 
        :total_days_balance="data.week_days_balance + data.free_days_balance" 
        :week_days_balance="data.week_days_balance" 
        :free_days_balance="data.free_days_balance"/>
      </div>
      <hr/>
    </div>
    <div class="card card-balance">
        <div class="card week-days">
          <img src="@/assets/img/week-days-icon.png"/>
          <div class="days-text">
            <span class="days-title">Semana</span>
            <span class="days-value">{{data.week_days_balance - week_days_selected}} dias</span>
          </div>
        </div>
        <div class="card free-days">
          <img src="@/assets/img/free-days-icon.png"/>
          <div class="days-text">
            <span class="days-title">Dias livres</span>
            <span class="days-value">{{data.free_days_balance - free_days_selected}} dias</span>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import CalendarComponent from "./CalendarComponent.vue";
import { apiService } from "../../../util/api.service";

export default {
  name: "AssetComponent",
  components: {
    CalendarComponent
  },
  props: {
    data: { type: Object },
    showCalendar: { required: false, type: Boolean, default: true },
    showBalance: { required: false, type: Boolean, default: true },
  },
  data() {
    return {
      date: new Date(),
      free_days_selected: 0,
      week_days_selected: 0,
    }
  },
  methods: {
    sendNewDate(value) {
      this.$emit("updateDate", value);
    },
    updateWeekDaysSelected(value) {
      this.week_days_selected = value;
    },
    updateFreeDaysSelected(value) {
      this.free_days_selected = value;
    },
    getImage() {
      if (this.data && this.data.asset && this.data.asset.photo) {
        return apiService.baseURL + this.data.asset.photo;
      }
      return require('@/assets/img/jetski.png');
    },
  },
  setup() {
    return {
      attrs: [
        {
          key: "hoje",
          highlight: true,
          dates: new Date(),
        },
      ],
    };
  },
  computed: {
    layout() {
      return this.$screens({
        // Default layout for mobile
        default: {
          columns: 1,
          rows: 1,
          isExpanded: true,
        },
        // Override for large screens
        lg: {
          columns: 4,
          rows: 2,
          isExpanded: false,
        },
      });
    },
  },
};
</script>


<style scoped>

.card-image .card-header {
  border-radius: 20px 20px 0 0;
  border: 0px;
  margin: -10px -10px 10px -10px;
  font-size: 12px;
  padding: 5px;
  text-transform: uppercase;
  font-weight: 700;
  box-shadow: none;
  background: transparent
    linear-gradient(90deg, #ebebeb 0%, #f8f8f8 49%, #ededed 100%) 0% 0%
    no-repeat padding-box;
}

.card-calendar {
  /* height: 200px; */
  margin-top: -20px;
  background:  #EFF1F3;
  color: white;
}

.card-calendar .card-body {
  padding-top: 35px;
}

hr {
  width: 295px;
  border: 1px solid #D1D3D4;
  margin-left:auto;
  margin-right: auto;
  margin-bottom: 0px;
}

.card-balance {
  border-radius: 0px 0px 8px 8px;
  background: #EFF1F3;
  width:100%;
  height: 100px;
  display:flex;
  flex-direction:row;
  justify-content: center;
}

.card-balance > .card {
  width: 120px;
  height: 60px;
  background: #FFFFFF;
  border-top: 3px solid #9CD9BF;
  border-radius: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

.week-days {
  margin-right: 8px !important
}

.free-days {
  margin-left: 8px !important;
  border-top: 3px solid #A1A7E3 !important;
}
.week-days, .free-days {
  display: flex;
  flex-direction: row;
}
.week-days img, .free-days img {
  width: 28px;
  margin-top: auto;
  margin-bottom:auto;
  margin-left: 8px;
}

.days-text {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 8px;
  text-align:left;
}

.days-text .days-title {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  color: black;
  line-height: 20px;
}

.days-value {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 800;
}

.week-days .days-value {
  color: #60CC9F;
}

.free-days .days-value {
  color: #5764EE;
}

.card-balance .card-body {
  padding-top: 20px;
}

.balance-badge {
  border-radius: 20px;
  font-size: 9px;
  background-color: white;
  color: black;
}

.rectangle {
  height: 6px;
  width: 2px;
  display: inline-block;
}

.balance-text {
  letter-spacing: -0.16px;
  font-weight: 900;
  font-size: 8px;
  margin: 0 5px 0 5px;
  text-transform: uppercase;
  color: #000000;
}

@media (max-width: 390px) {
  .balance-text {
    letter-spacing: -0.16px;
    font-weight: 900;
    font-size: 6px;
    margin: 0 5px 0 5px;
    text-transform: uppercase;
    color: #000000;
  }
}

.balance-week {
  margin-right: 5px;
}

.balance-week .rectangle {
  background-color: #00a0a0;
}

.balance-week .balance-badge {
  letter-spacing: -0.16px;
  font-size: 8px;
  color: #00a0a0;
}

.balance-weekend .rectangle {
  background-color: #ff7f00;
}

.balance-weekend .balance-badge {
  letter-spacing: -0.16px;
  font-size: 8px;
  color: #ff7f00;
}

.days {
  color: black;
}

.asset-description {
  display: flex;
}

.asset-info {
  margin-left: 24px;
  position: relative;
  width: 50%;
}

.reserve-info {
  border-radius: 50%;
  position: absolute;
  color: black;
}

.card-image {
  border-radius: 20px;
  z-index: 1;
  background-color: white;
  overflow: hidden;
}

.card-image .img {
  max-height: 110px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  display: block;
  width: 160px !important;
  height: 100% !important;
  -o-object-fit: cover;
  border-radius: 15px;
  object-fit: cover;
}

.asset-name {
  font-family: 'Open Sans', sans-serif;
  color: black;
  position: absolute;
  top: 1vh;
  font-size: 18px;
  font-weight: bolder;
  text-align: left;
}

.asset-time {
  font-family: 'Open Sans', sans-serif;
  color: black;
  position: absolute;
  bottom: 1vh;
  font-size: 14px;
  text-align: left;
}

.assetcomponent {
  margin: 0 auto;
  width: 90vw;
}
</style>

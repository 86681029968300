<template>
    <div id="dropdown-menu" class="dropdownmenu-wrapper">
        <div class="menu-header" id="page-wrap">
            <div class="profile-image">
                <img :src="profilePhoto" alt="Avatar" />
            </div>
            <div class="profile-info">
                <p class="greeting-text" style="text-align: left; font-size: 23px;"><span>Olá, {{ username }}!</span>
                </p>
                <p style="text-align: left"><span>Cotista</span></p>
                <p style="text-align: left">
                    <router-link to="/changepassword">
                        <button class="changepass-button" @click="changePassword">
                            Alterar senha
                        </button>
                    </router-link>
                </p>
            </div>
        </div>
        <hr />
        <div class="menu-body">
            <ul class="menu-items">
                <li>
                    <router-link to="/account" style="text-decoration: none;">
                        <svg class="img-menu" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
                            <path
                                d="M61.44,0A61.31,61.31,0,0,1,84.92,4.66h0A61.66,61.66,0,0,1,118.21,38l.1.24a61.39,61.39,0,0,1-.1,46.73h0A61.42,61.42,0,0,1,38,118.21h0A61.3,61.3,0,0,1,18,104.88l0,0A61.5,61.5,0,0,1,4.66,84.94l-.09-.24A61.48,61.48,0,0,1,4.66,38v0A61.37,61.37,0,0,1,18,18l0,0A61.5,61.5,0,0,1,37.94,4.66l.24-.09A61.35,61.35,0,0,1,61.44,0ZM48.78,79.89a16.44,16.44,0,0,1-1.34-1.62c-2.6-3.56-4.93-7.58-7.27-11.33-1.7-2.5-2.59-4.73-2.59-6.52s1-4.13,3-4.64a101,101,0,0,1-.18-11.73A16.86,16.86,0,0,1,41,41.11a17,17,0,0,1,7.58-9.64,19.26,19.26,0,0,1,4.11-2c2.59-1,1.34-4.91,4.19-5C63.54,24.33,74.52,30,78.8,34.68a16.91,16.91,0,0,1,4.38,11l-.27,10.57a3.31,3.31,0,0,1,2.41,2.41c.36,1.43,0,3.39-1.25,6.16h0c0,.09-.09.09-.09.18-2.75,4.53-5.62,9.78-8.78,14-1.59,2.12-2.9,1.75-1.54,3.78,6.45,8.87,19.18,7.64,27,13.55a52.66,52.66,0,0,0,9.36-54.72l-.09-.2A52.7,52.7,0,0,0,98.55,24.33h0a52.63,52.63,0,0,0-57-11.49l-.21.09a52.53,52.53,0,0,0-17,11.4h0a52.63,52.63,0,0,0-11.49,57l.09.21A52.66,52.66,0,0,0,22.19,96.3c7.85-5.91,20.58-4.68,27-13.55,1.12-1.68.83-1.52-.44-2.86Z" />
                        </svg>
                        <span class="text-menu">Meus Dados</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/treinamentos" style="text-decoration: none;">
                        <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" class="img-menu"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40"
                            style="enable-background:new 0 0 40 40;" xml:space="preserve">
                            <g id="_9781758071548235380" transform="translate(-62.919 0)">
                                <g id="Grupo_420" transform="translate(62.919 0)">
                                    <path id="Caminho_82" d="M34.75,18.06l-1.95-2.66c-0.12-0.17-0.12-0.41,0-0.58l1.91-2.7c0.16-0.23,0.1-0.54-0.13-0.7
			c-0.01-0.01-0.03-0.02-0.05-0.03l-2.91-1.56c-0.19-0.1-0.29-0.31-0.24-0.52l0.55-3.26c0.05-0.27-0.14-0.53-0.41-0.57
			c-0.02,0-0.05-0.01-0.07-0.01l-3.3-0.14c-0.21-0.01-0.4-0.15-0.46-0.36l-0.93-3.2c-0.08-0.26-0.35-0.42-0.62-0.34
			c-0.02,0.01-0.04,0.01-0.06,0.02l-3.04,1.31c-0.2,0.08-0.43,0.03-0.57-0.12l-2.21-2.46c-0.18-0.21-0.5-0.23-0.71-0.04
			c-0.01,0.01-0.03,0.03-0.04,0.04l-2.17,2.5c-0.14,0.16-0.37,0.22-0.56,0.13L13.7,1.54c-0.25-0.11-0.55,0.02-0.65,0.27
			c-0.01,0.02-0.01,0.04-0.02,0.06l-0.87,3.19c-0.06,0.21-0.24,0.35-0.45,0.37L8.4,5.62C8.12,5.63,7.91,5.87,7.93,6.15
			c0,0.02,0,0.04,0.01,0.05l0.6,3.25c0.04,0.21-0.06,0.42-0.24,0.52l-2.89,1.6c-0.24,0.14-0.33,0.44-0.19,0.68
			c0.01,0.02,0.02,0.03,0.03,0.04l1.96,2.66c0.12,0.17,0.12,0.41,0,0.58l-1.91,2.7c-0.16,0.23-0.1,0.54,0.13,0.7
			c0.01,0.01,0.03,0.02,0.05,0.03l2.91,1.56c0.19,0.1,0.29,0.31,0.24,0.52l-0.55,3.26C8,24.6,8.18,24.86,8.46,24.9
			c0.02,0,0.05,0.01,0.07,0.01l3.3,0.14c0.21,0.01,0.4,0.15,0.46,0.36l0.92,3.18c0.08,0.26,0.35,0.42,0.62,0.34
			c0.02-0.01,0.04-0.01,0.06-0.02l3.07-1.29c0.2-0.08,0.43-0.03,0.57,0.12l2.21,2.46c0.18,0.21,0.5,0.23,0.71,0.04
			c0.01-0.01,0.03-0.03,0.04-0.04l2.18-2.49c0.14-0.16,0.37-0.22,0.56-0.13l3.06,1.26c0.25,0.11,0.55-0.02,0.65-0.27
			c0.01-0.02,0.01-0.04,0.02-0.06l0.87-3.19c0.06-0.21,0.24-0.35,0.45-0.37l3.3-0.19c0.28-0.02,0.49-0.26,0.47-0.53
			c0-0.02,0-0.04-0.01-0.05l-0.6-3.25c-0.04-0.21,0.06-0.42,0.24-0.52l2.89-1.6c0.24-0.12,0.33-0.42,0.2-0.65
			C34.79,18.12,34.77,18.09,34.75,18.06z M20,24.17c-4.96,0-8.98-4.02-8.98-8.98S15.04,6.21,20,6.21c4.96,0,8.98,4.02,8.98,8.98
			C28.97,20.14,24.95,24.16,20,24.17z" />
                                    <path id="Caminho_83" d="M28.08,29.97c-0.49,0.49-1.15,0.77-1.85,0.77c-0.25,0-0.5-0.04-0.74-0.11L23.38,30l-1.63,1.49
			c-0.06,0.05-0.11,0.1-0.16,0.14l2.62,8.11c0.06,0.19,0.26,0.3,0.46,0.25c0.07-0.02,0.14-0.07,0.19-0.13l1.9-2.44
			c0.09-0.12,0.25-0.17,0.39-0.13l2.97,0.87c0.2,0.06,0.4-0.06,0.46-0.25c0.02-0.07,0.02-0.14,0-0.21L28.08,29.97z" />
                                    <path id="Caminho_84" d="M16.62,30l-2.11,0.64c-0.24,0.07-0.49,0.11-0.74,0.11c-0.69,0-1.36-0.28-1.85-0.77l-2.49,7.71
			c-0.06,0.19,0.04,0.4,0.24,0.46c0.07,0.02,0.15,0.02,0.22,0l2.97-0.87c0.15-0.04,0.3,0.01,0.39,0.13l1.9,2.44
			c0.12,0.16,0.36,0.19,0.52,0.07c0.06-0.05,0.1-0.11,0.13-0.18l2.62-8.11c-0.06-0.04-0.11-0.09-0.16-0.14L16.62,30z" />
                                    <path id="Caminho_85" d="M25.32,12.96l-3.39-0.24l-1.28-3.15c-0.15-0.36-0.57-0.54-0.93-0.38c-0.17,0.07-0.31,0.21-0.38,0.38
			l-1.28,3.15l-3.39,0.24c-0.39,0.03-0.69,0.37-0.66,0.77c0.01,0.19,0.1,0.36,0.25,0.48l2.59,2.18l-0.81,3.29
			c-0.1,0.38,0.13,0.77,0.51,0.86c0.19,0.05,0.39,0.02,0.55-0.09l2.88-1.79l2.88,1.79c0.33,0.21,0.77,0.11,0.98-0.23
			c0.1-0.16,0.13-0.36,0.09-0.55l-0.81-3.29l2.59-2.18c0.3-0.25,0.34-0.69,0.1-1C25.68,13.07,25.51,12.97,25.32,12.96z" />
                                </g>
                            </g>
                        </svg>
                        <span class="text-menu">Treinamentos</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/balance" style="text-decoration: none;">
                        <img class="img-menu" src="@/assets/img/dex.png" />
                        <span class="text-menu">Extrato Dex</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/quotas" style="text-decoration: none;">
                        <img class="img-menu" src="@/assets/img/quotas.png" />
                        <span class="text-menu">Cotas</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/timeshare" style="text-decoration: none;">
                        <img class="img-menu" src="../assets/img/timeshare.svg" />
                        <span class="text-menu">Time share</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/reserves" style="text-decoration: none;">
                        <svg class="img-menu" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.1116 20.385C4.58872 20.385 0.111572 15.9079 0.111572 10.385C0.111572 4.86216 4.58872 0.38501 10.1116 0.38501C15.6344 0.38501 20.1116 4.86216 20.1116 10.385C20.1055 15.9053 15.6319 20.3789 10.1116 20.385ZM10.0956 18.385H10.1116C14.5283 18.3806 18.1058 14.7977 18.1036 10.381C18.1014 5.9643 14.5203 2.38501 10.1036 2.38501C5.68686 2.38501 2.10578 5.9643 2.10357 10.381C2.10136 14.7977 5.67886 18.3806 10.0956 18.385ZM8.11157 15.385L4.11157 11.385L5.52157 9.97501L8.11157 12.555L14.7016 5.96501L16.1116 7.38501L8.11157 15.385Z"
                                fill="black" />
                        </svg>
                        <span class="text-menu">Reservas</span>
                    </router-link>
                </li>
                <li>
                    <router-link to="/last-active-confirmation-code" style="text-decoration: none;">
                        <img class="img-menu" src="@/assets/img/key.png" />
                        <span class="text-menu">Código validador</span>
                    </router-link>
                </li>
            </ul>
            <hr />
            <div class="leave-app">
                <a href="#" @click="logout">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.1116 18.4362H7.11157C6.007 18.4362 5.11157 17.5408 5.11157 16.4362V12.4362H7.11157V16.4362H16.1116V2.43625H7.11157V6.43625H5.11157V2.43625C5.11157 1.33168 6.007 0.436249 7.11157 0.436249H16.1116C17.2161 0.436249 18.1116 1.33168 18.1116 2.43625V16.4362C18.1116 17.5408 17.2161 18.4362 16.1116 18.4362ZM9.11157 13.4362V10.4362H0.111572V8.43625H9.11157V5.43625L14.1116 9.43625L9.11157 13.4362Z"
                            fill="black" />
                    </svg>
                    <span class="text-menu">Sair do app</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { apiService } from "../util/api.service";

export default {
    name: "DropdownMenu",
    computed: {
        username: function () {
            return localStorage.getItem('username') != undefined ? localStorage.getItem('username') : "Fulano";
        },
        profilePhoto: function () {
            if (localStorage.getItem('profilePhoto') != "null" && localStorage.getItem('profilePhoto') != undefined) {
                return apiService.baseURL + localStorage.getItem('profilePhoto');
            }
            return require("../assets/img/img_avatar.png");
        },
    },
    methods: {
        toggleMenu(menuState) {
            let el = document.getElementById("dropdown-menu");
            if (menuState) {
                el.style.top = '42px';
                el.style.visibility = 'visible';
            } else {
                el.style.top = '-400px';
                el.style.visibility = 'hidden';
            }
        },
        logout() {
            apiService.logout(
                () => {
                    console.log("Deslogando usuário logado");
                    this.$router.push("/");
                },
                () => {
                    console.log("Nenhum usuário logado");
                    this.$router.push("/");
                }
            );
        },
    },
}
</script>

<style>
.dropdownmenu-wrapper {
    position: absolute;
    width: 100%;
    height: 600px;
    top: -300px;
    visibility: hidden;
    background: #EBEEF2;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 30px 30px;
    z-index: 100;
    transition: all 0.4s ease-out;
}

.header .bm-menu {
    padding-top: 0px !important;
    border-radius: 0px 20px 20px 0px;
    opacity: 0.98;
}

.header .bm-item-list {
    margin-left: 0px !important;
    height: 100%;
    overflow-y: hidden;
}

.header .bm-item-list>* {
    padding: 0px;
}

.header .bm-burger-bars {
    border-radius: 10px;
    height: 5px;
}
</style>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');

#dropdown-menu {
    text-align: left;
}

.greeting-text {
    font-family: 'Alfa Slab One', cursive;
    color: black;
}

.leave-app {
    margin: 8px 0px 30px 36px;
    /* Remove margins */
}

.leave-app a {
    font-size: 18px !important;
    text-decoration: none !important;
    color: black !important;
}

hr {
    width: 90%;
    margin: 0 auto;
    border: 1px solid #D1D1D1;
}

.menu-header {
    margin-left: 43px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 200px;
    border-radius: 0px 20px 0px 0px;
    opacity: 0.98;
}

.menu-body {
    border-radius: 0px 0px 20px 0px;
    opacity: 0.98;
    height: 100%;
}

ul.menu-items {
    list-style-type: none;
    /* Remove bullets */
    padding: 0;
    /* Remove padding */
    margin: 30px 0px 30px 36px;
    /* Remove margins */
}

.menu-items>li {
    margin-bottom: 12px;
}

.menu-items>li:last-child {
    margin-bottom: 0px;
}

.profile-image {
    width: 90px;
    height: 90px;
    margin-top: 24px;
}

.profile-image img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}

.profile-info {
    margin: 24px auto 0 24px;
    font-size: 16px;
    color: black;
}


.profile-info p {
    padding-top: 0px;
    margin-bottom: 0px;
}

.logout-button {
    font-weight: bold;
    font-size: 11px;
    flex: 1 0 21%;
    margin: 0px 36px;
    text-align: center;
    max-width: 120px;
    border-radius: 4px;
}

.changepass-button {
    font-weight: bold;
    font-size: 11px;
    flex: 1 0 21%;
    margin-top: 8px;
    text-align: center;
    width: 120px;
    height: 26px;
    border: 1px solid #000000;
    border-radius: 40px;
}

.logout-button svg {
    margin-right: 4px;
    margin-bottom: 2px;
}

.img-menu {
    position: relative;
    height: 20px !important;
    width: 20px !important;
    vertical-align: sub;
}

.text-menu {
    position: relative;
    height: 20px !important;
    width: 20px !important;
    font-size: 20px;
    text-decoration: none;
    color: black;
    margin-left: 20px;
}
</style>
<template>
  <div v-if="data" class="assetcomponent">
    <div class="card card-image">
      <div class="card-body text-center">
        <div class="asset-description-timeshare">
          <img class="img" :src="getImage()" />
          <div class="asset-info-timeshare">
            <div class="asset-name-timeshare">{{ data.name }}</div>
            <div class="asset-time-timeshare"><b>Horário:</b> {{ data.working_hours }}</div>
            <div class="asset-dex-cost-day-timeshare">Custo: {{ data.dex_per_hour }} dex/hora</div>
            <div class="asset-dex-cost-reserve-timeshare">Reserva: {{ data.dex_per_day }} dex/dia</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showCalendar" class="card card-calendar">
      <div class="card-body text-center">
        <CalendarComponent @changeDay="sendNewDate" @updateDaysSelected="updateDaysSelected" :asset_id="data.id" :isTimeshare="true"/>
      </div>
      <hr />
    </div>
    <div class="card-balance">
      <div class="card dex-cost-day">
        <span class="days-title">Custo total de reserva</span>
        <span class="days-value">{{ days_selected * data.dex_per_day }} DEX</span>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarComponent from "./CalendarComponent.vue";
import { apiService } from "../../../util/api.service";

export default {
  name: "TimeShareAssetComponent",
  components: {
    CalendarComponent
  },
  props: {
    data: { type: Object },
    showCalendar: { required: false, type: Boolean, default: true },
    showBalance: { required: false, type: Boolean, default: true },
  },
  data() {
    return {
      date: new Date(),
      days_selected: 0,
    }
  },
  methods: {
    sendNewDate(value) {
      this.$emit("updateDate", value);
    },
    updateDaysSelected(value) {
      this.days_selected = value;
    },
    getImage() {
      if (this.data && this.data.photo) {
        return apiService.baseURL + "/media/" + this.data.photo;
      }
      return require('@/assets/img/jetski.png');
    },
  },
  setup() {
    return {
      attrs: [
        {
          key: "hoje",
          highlight: true,
          dates: new Date(),
        },
      ],
    };
  },
  computed: {
    layout() {
      return this.$screens({
        // Default layout for mobile
        default: {
          columns: 1,
          rows: 1,
          isExpanded: true,
        },
        // Override for large screens
        lg: {
          columns: 1,
          rows: 1,
          isExpanded: false,
        },
      });
    },
  },
};
</script>


<style scoped>
.assetcomponent {
  margin: 0 auto;
  width: 90vw;
}

.asset-info-timeshare {
  display: flex;
  flex-direction: column;
}

.asset-name-timeshare {
  font-family: 'Open Sans', sans-serif;
  color: black;
  font-size: 18px;
  font-weight: bolder;
  text-align: left;
}

.asset-time-timeshare,
.asset-dex-cost-day-timeshare,
.asset-dex-cost-reserve-timeshare {
  font-family: 'Open Sans', sans-serif;
  color: #7C878C;
  font-size: 14px;
  text-align: left;
}

.asset-time-timeshare {
  font-weight: 600;
  margin-bottom: 8px;
}

.asset-dex-cost-day-timeshare,
.asset-dex-cost-reserve-timeshare {
  font-style: italic;
}

.asset-description-timeshare {
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
}

.card-image .card-header {
  border-radius: 20px 20px 0 0;
  border: 0px;
  margin: -10px -10px 10px -10px;
  font-size: 12px;
  padding: 5px;
  text-transform: uppercase;
  font-weight: 700;
  box-shadow: none;
  background: transparent linear-gradient(90deg, #ebebeb 0%, #f8f8f8 49%, #ededed 100%) 0% 0% no-repeat padding-box;
}

.card-image {
  border-radius: 20px;
  z-index: 1;
  background-color: white;
  overflow: hidden;
}

.card-image .img {
  max-height: 110px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  display: block;
  width: 160px !important;
  height: 100% !important;
  -o-object-fit: cover;
  border-radius: 15px;
  object-fit: cover;
}

.card-calendar {
  margin-top: -20px;
  background: #EFF1F3;
  color: white;
}

.card-calendar .card-body {
  padding-top: 35px;
}

hr {
  width: 295px;
  border: 1px solid #D1D3D4;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
}

.card-balance {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 0px 0px 8px 8px;
  background: #EFF1F3;
  height: 100px;
}

.card-balance>.card {
  height: 60px;
  border-radius: 5px;
  margin: auto;
}

.dex-cost-day {
  padding: 0 8px 0 8px;
  border-top: 3px solid #A0AEC0;
}

.dex-cost-day {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #26292B;
  font-family: "Open Sans", sans-serif;
}

.days-title {
  font-size: 10px;
  font-weight: bold;
  margin: 4px;
}

.days-value {
  font-size: 16px;
  font-weight: 800;
}
</style>

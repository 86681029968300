<template>
  <div class="calendarcomponent">

    <v-date-picker ref="datePicker" trim-weeks v-model="date" @dayclick="dayClicked"
      @update:from-page="fromPageFunction" :min-date="new Date()" :disabled-dates="disabledDates"
      :columns="layout.columns" :rows="layout.rows" :is-expanded="layout.isExpanded" :attributes="attrs"
      :firstDayOfWeek="2" locale="pt-BR" :masks="masks" :select-attribute="selectAttribute" />

    <GDialog :contentClass="'bottomModal'" v-model="dialogState" max-width="500"
      transition="custom-from-bottom-transition">
      <div v-bind:class="[successfull ? 'wrapper wrapper-success' : 'wrapper wrapper-failure']" @close="closeDialog()">
        <div class="content-modal">
          <div class="title" ref="modalTitle">
            Saldo de dias {{ type }} insuficiente.<br />
          </div>
        </div>
        <div class="actions-modal actions">
          <button ref="modalButton" class="btn button-black rounded-button" @click="dialogState = false">
            OK
          </button>
        </div>
      </div>
    </GDialog>

  </div>
</template>

<script>
import { apiService } from "../../../util/api.service";

export default {
  name: "CalendarComponent",
  props: {
    asset_id: undefined,
    total_days_balance: undefined,
    week_days_balance: undefined,
    free_days_balance: undefined,
    isTimeshare: Boolean,
  },
  data() {
    return {
      days: [],
      weekDaysDate: [],
      freeDaysDate: [],
      month: undefined,
      year: undefined,
      week_days_selected: 0,
      free_days_selected: 0,
      days_selected: 0,
      datesReserved: undefined,
      attrs: undefined,
      disabledDates: [],
      selectAttribute: {
        dot: {
          style: {
            backgroundColor: "transparent"
          }
        },
      },
      type: "de semana",
      dialogState: false,
      successfull: false,
    };
  },
  methods: {
    createNormalizedDate(date) {
      // Create a date object with the actual timezone, but preserve the day, month and year
      const localOffset = new Date().getTimezoneOffset() * 60000;
      return new Date(new Date(date).getTime() + localOffset);
    },
    hasEnoughBalance(isWeekend) {
      if (isWeekend) {
        if (this.free_days_selected === this.free_days_balance) {
          this.type = "livres";
          this.dialogState = true;
          this.successfull = false;
          return false;
        }
      } else {
        if (this.week_days_selected + this.free_days_selected === this.total_days_balance) {
          this.type = "de semana e livres";
          this.dialogState = true;
          this.successfull = false;
          return false;
        }
      }
      return true;
    },
    dayClicked(value) {
      this.dialogState = false;
      if (value.isDisabled) {
        return;
      }

      const dayAlreadySelected = this.days.find((day) => day.id === value.id);
      const dayOfWeek = value.date.getDay(); // 6 = Saturday, 0 = Sunday
      const isWeekend = dayOfWeek === 6 || dayOfWeek === 0;

      if (dayAlreadySelected === undefined) {
        if (!this.hasEnoughBalance(isWeekend)) return;

        this.days_selected += 1;
        let isFreeDay = false;
  
        if (!isWeekend && this.week_days_selected < this.week_days_balance) {
          this.week_days_selected += 1;
          isFreeDay = false;
        } else if (isWeekend || (this.week_days_selected >= this.week_days_balance && !isWeekend)) {
          this.free_days_selected += 1;
          isFreeDay = true;
        }

        if (this.isTimeshare) {
          isFreeDay = false;
        }

        if (isFreeDay) {
          this.freeDaysDate.push({
            id: value.id,
            date: value.date,
            isFreeDay: isFreeDay,
          });
        } else {
          this.weekDaysDate.push({
            id: value.id,
            date: value.date,
            isFreeDay: isFreeDay,
          })
        }

        this.days.push({
          id: value.id,
          date: value.date,
          isFreeDay: isFreeDay,
        });
      } else {
        const index = this.days.indexOf(dayAlreadySelected);
        const isFreeDay = dayAlreadySelected.isFreeDay;
        this.days.splice(index, 1);

        if (isFreeDay) {
          const freeIndex = this.freeDaysDate.indexOf(dayAlreadySelected);
          this.freeDaysDate.splice(freeIndex, 1);
          this.free_days_selected -= 1;
          this.days_selected -= 1;
        } else {
          const weekIndex = this.weekDaysDate.indexOf(dayAlreadySelected);
          this.weekDaysDate.splice(weekIndex, 1);
          this.week_days_selected -= 1;
          this.days_selected -= 1;
        }
      }

      this.setAttrs();
      // notify listeners
      this.emitEvents(value);
    },
    // set the days that are already taken (reserved)
    async fromPageFunction(page) {
      this.month = page.month;
      this.year = page.year;
      await apiService.getReservedDays(
        this.month,
        this.year,
        this.asset_id,
        (response) => {
          this.datesReserved = response.data;
        }
      );

      this.disabledDates = this.datesReserved.flatMap((dateTaken) => dateTaken.days.map((date) => this.createNormalizedDate(date)));

      // update the dates in calendar to display the ones already taken
      this.setAttrs();
    },
    setAttrs() {
      this.attrs = [];
      this.attrs = this.attributes.concat(
        this.datesReserved.map((dateTaken) => ({
          key: dateTaken.name,
          highlight: {
            contentClass: "reserved-date",
            color: "red",
          },
          dates: dateTaken.days.map((date) => this.createNormalizedDate(date)),
        }))
      );
      console.log(this.attrs)
    },
    emitEvents(value) {
      if (value.date >= new Date().setHours(0, 0, 0, 0)) {
        this.$emit("changeDay", value.id);
      } else {
        this.$emit("changeDay", undefined);
      }

      this.$emit("updateFreeDaysSelected", this.free_days_selected);
      this.$emit("updateWeekDaysSelected", this.week_days_selected);
      this.$emit("updateDaysSelected", this.days_selected);
    },
  },
  computed: {
    masks() {
      return {
        weekdays: "WWW",
      };
    },
    attributes() {
      let ret = this.weekDaysDate.map((date) => ({
        key: "weekdays",
        highlight: {
          fillMode: "solid",
          contentClass: "week-day-date"
        },
        dates: date.date,
      }));
      ret = ret.concat(this.freeDaysDate.map((date) => ({
        key: "weekenddays",
        highlight: {
          fillMode: "solid",
          contentClass: "weekend-day-date"
        },
        dates: date.date,
      })));
      return ret;
    },
    layout() {
      return this.$screens({
        // Default layout for mobile
        default: {
          columns: 1,
          rows: 1,
          isExpanded: true,
        },
        // Override for large screens
        lg: {
          columns: 1,
          rows: 1,
          isExpanded: false,
        },
      });
    },
  },
};
</script>

<style>
.reserved-date {
  color: red !important;
}

.week-day-date {
  color: white;
  background-color: #60CC9F !important;
}

.weekend-day-date {
  color: white;
  background-color: #5764EE !important;
}

.vc-day {
  background-color: transparent !important;
}

.title {
  font: normal normal 900 24px/28px Helvetica Neue;
  color: black;
  letter-spacing: 0px;
  margin-bottom: -8px;
  margin: auto;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  /* margin-bottom: 20px; */
}

.btn:hover {
  color: #ffffff;
}

.unselected {
  color: black !important;
  font-weight: 0;
}

.actions-modal {
  display: flex;
  justify-content: center;
  border-top: 0px;
  margin: 30px auto 20px;
}

.content-modal {
  padding: 20px;
}

.wrapper {
  color: #000;
  border-radius: 15px 15px 0px 0px !important;
}

.wrapper-failure {
  border-top: 5px solid #FF0000;
}

.wrapper-success {
  border-top: 5px solid #3AC948;
}

.content-modal {
  padding: 20px;
  margin: 50px 0 50px 0;
}

.content-modal .title {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 2px;
}

.modal-button {
  font-family: 'Alfa Slab One', cursive;
  background: black;
  color: white;
  width: 174px;
  height: 42px;
}

.button-black {
  font-family: 'Open Sans', sans-serif;
  background: black;
  color: white;
  width: 174px;
  height: 42px;
}

@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
</style>

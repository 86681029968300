<template>
    <swiper v-if="categories.length"
    :pagination="pagination"
    :modules="modules"
    @slide-change="onSlideChange">
      <swiper-slide
        v-for="category in categories"
        :key="category"
        :data-title="category.category"
      >
        <div class="swiper-no-swiping asset">
          <Carousel :items-to-show="1.0">
            <Slide v-for="asset in category.assets" :key="asset" :data-title="asset.id">
              <div class="carousel__item">
                <div class="card card-image">
                  <div :class="asset.has_training ? 'overlay' : 'overlay-active'">
                    <div v-if="!asset.has_training" class="overlay-text">
                      <h5>TREINAMENTO PENDENTE</h5>
                    </div>
                  </div>
                  <div class="card-presentation card-body text-center">
                    <img v-if="asset.photo" class="img" :src="getBackend()+'/media/' + asset.photo" />
                    <img v-else class="img" :src="getImage(asset)" />
                    <div class="dex-balance"></div>
                  </div>
                </div>
                <div class="card card-details">
                  <div class="card-body quota-swiper-body">
                    <div class="details-name">
                      <h4>{{asset.name}}</h4>
                    </div>
                    <div class="details">
                      <div class="details-title">Modalidade/Local</div>
                      <p v-if="asset.quota_type == 'mobile'" class="details-text">Móvel</p>
                      <p v-if="asset.quota_type == 'fixed'" class="details-text">Fixo</p>
                    </div>
                    <div class="details">
                      <div class="details-title">Custo por hora</div>
                      <p class="details-text">{{ asset.dex_per_hour }} dex/hora</p>
                    </div>
                    <div class="details">
                      <div class="details-title">Custo de reserva</div>
                      <p class="details-text">{{ asset.dex_per_day }} dex/dia</p>
                    </div>
                    <div class="details" style="line-height: 14px; border:0">
                      <div class="details-title" style="font-weight: bold;font-size: 13px;min-width: 155px;">Horário de funcionamento:</div>
                      <p class="details-text" style="font-weight: normal;padding-top: 1px;font-size: 13px;">{{asset.working_hours}}</p>
                    </div>
                    <div @click="redirectToReserveDetails(asset)" class="reservar">
                        Reservar
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
            <template #addons>
              <CarouselPagination />
            </template>
          </Carousel>
        </div>
      </swiper-slide>
    </swiper>
</template>


<script>
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Carousel, Slide, Pagination as CarouselPagination } from 'vue3-carousel';
import { apiService } from "../../../util/api.service";
import { ref } from "vue";
import "swiper/css";
import "swiper/css/pagination";
import 'vue3-carousel/dist/carousel.css';
import dayjs from "dayjs";
import "../../../assets/css/quotas.css";

export default {
  name: "TimeShareSwiperComponent",
  components: {
    Swiper,
    SwiperSlide,
    CarouselPagination,
    Carousel,
    Slide,
  },
  methods: {
    formatDate(dateString) {
      const date = dayjs(dateString);
      return date.format("DD/MM/YYYY");
    },
    getImage(asset) {
      if (asset.photo) {
        return apiService.baseURL + '/' + asset.photo;
      }
      return require('@/assets/img/jetski.png');
    },
    getBackend: function() {
      return apiService.baseURL;
    },
    redirectToReserveDetails: function (asset) {
      if (asset && asset.has_training) {
        this.$router.push({name: "create-reserve-timeshare", params: {asset_id: asset.id}});
      }
    },
    onSlideChange() {
      let target = document.getElementsByClassName("header")[0];
      this.animate(document.scrollingElement || document.documentElement, "scrollTop", "", 0, target.offsetTop, 250, true);
    },
    animate: function(elem, style, unit, from, to, time, prop) {
      if (!elem) {
        return;
      }
      let start = new Date().getTime(),
        timer = setInterval(function() {
          let step = Math.min(1, (new Date().getTime() - start) / time);
          if (prop) {
            elem[style] = (from + step * (to - from)) + unit;
          } else {
            elem.style[style] = (from + step * (to - from)) + unit;
          }
          if (step === 1) {
            clearInterval(timer);
          }
        }, 25);
      if (prop) {
        elem[style] = from + unit;
      } else {
        elem.style[style] = from + unit;
      }
    }
  },
  async setup() {
    let categories = ref([]);
    await apiService.getTimeShareList(
      (response) => {
        const data = response.data;
        const grouped = data.reduce((acc, item) => {
        const category = item.category_name;
        if (!acc[category]) {
          acc[category] = { category, assets: [] };
        }
        acc[category].assets.push(item);
        return acc;
        }, {});
        categories.value = Object.values(grouped);
    });
    return {
      categories,
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
            if (categories.value != undefined && categories.value[index] !== "undefined") {
                return (
                '<span class="' +
                className +
                '">' +
                categories.value[index].category +
                "</span>"
                );
            }
        },
      },
      modules: [Pagination],
    };
  },
};

</script>


<style scoped>
  .quota-swiper-body {
    padding: 1rem 0rem !important;
  }
  .card-image .img {
    max-height: 200px !important;
    align-self: center;
    width: 100% !important;
    object-fit: cover;
    border-radius: 10px 10px 0px 0px;
  }
  .overlay-active {
    position: absolute;
    width: 100%;
    height: 626px;
    max-width: 360px;
    z-index: 3;
    background-color: rgba(102, 102, 102, 0.8);
    border-radius: 10px 10px 20px 20px;
  }

  .overlay-text {
    margin-top: 90%;
    background-color: rgba(208, 211, 215, 0.8);
    width: 90%;
    height: 50px;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 6px;
  }

  .overlay-text h5{
    margin-bottom: 0px;
    align-self: center;
    color:#31506f;
    font-weight: 700;
  }

</style>